import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";
// import "./assets/css/animate.min.css";
class FrontendLayout extends React.Component {
  render() {
    return (
      <>
        <Header />
        {/* <main>{this.props.children}</main> */}
        <Outlet />
        <Footer />
      </>
    );
  }
}
export default FrontendLayout;
