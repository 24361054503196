import React, { Component } from "react";
import Header from "../layout/frontend/Header";
import Footer from "../layout/frontend/Footer";
class Home extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="main-panel">
        <Header></Header>
        <div class="slider-area position-relative">
          <div class="slider-active">
            <div class="single-slider slider-height d-flex align-items-center">
              <div class="container">
                <div class="row">
                  <div class="col-xl-9 col-lg-9 col-md-10">
                    <div class="hero__caption">
                      <span data-animation="fadeInLeft" data-delay="0.1s">
                        Hi This is Zacson
                      </span>
                      <h1 data-animation="fadeInLeft" data-delay="0.4s">
                        Gym Trainer
                      </h1>
                      <a
                        href="courses.html"
                        class="border-btn hero-btn"
                        data-animation="fadeInLeft"
                        data-delay="0.8s"
                      >
                        My Courses
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Home;
