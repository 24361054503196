import React, { Component } from "react";
import Footer from "../../layout/frontend/Footer";
import Header from "../../layout/frontend/Header";
import { ROOT_URL } from "../../../helper/vars";
import { FetchPost } from "../../../helper/helpers";

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      password: "",
      shouldRedirect: false,
      showToast: false,
      textToast: "",
    };
  }
  componentDidMount() {
    console.log("redux data", this.props.user);
  }
  updateState = (stateVar, stateVal) => {
    this.setState({
      [stateVar]: stateVal,
    });
  };
  validation = () => {
    if (this.state.name == "") {
      alert("Name is required");
      return false;
    }
    if (this.state.email == "") {
      alert("Email is required");
      return false;
    }
    if (this.state.password == "") {
      alert("Password is required");
      return false;
    }
    return true;
  };
  signupSubmit = () => {
    //console.log("redux data", this.props.user.authToken);
    //return false;
    if (this.validation) {
      let postData = {
        name: this.state.name,
        email: this.state.email,
        password: this.state.password,
        role: "SUPER_ADMIN",
      };
      console.log("Post data " + JSON.stringify(postData));
      this.setState({ isVisibleLoader: true });
      FetchPost("register", postData).then((result) => {
        //this.setState({ isVisibleLoader: false });
        console.log("response data : ", JSON.stringify(result));
        this.setState({ showToast: true, textToast: result.msg });
        setTimeout(() => {
          this.setState({ showToast: false });
        }, 5000);
      });
    }
  };
  render() {
    const { name, email, password, showToast, textToast } = this.state;
    return (
      <>
        <Header></Header>
        <div className="slider-area position-relative">
          <div className="slider-active">
            <div className="single-slider slider-height9 d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div className="col-xl-9 col-lg-9 col-md-10">
                    <div className="hero__caption">
                      <h1 data-animation="fadeInLeft" data-delay="0.4s">
                        REGISTRATION
                      </h1>
                    </div>
                  </div>
                </div>

                <section className="about-area2 fix pb-padding pt-50 pb-80">
                  <div className="container">
                    <div className=" ">
                      {showToast && (
                        <div className="toast-message">
                          <p className="toast">{textToast}</p>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <input
                            className="form-control textboxstyle"
                            name="name"
                            id="name"
                            type="text"
                            value={name}
                            placeholder="Enter Your Name"
                            onChange={(e) => {
                              this.updateState("name", e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <input
                            className="form-control valid textboxstyle"
                            name="email"
                            id="email"
                            type="email"
                            value={email}
                            placeholder="Email"
                            onChange={(e) => {
                              this.updateState("email", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <input
                            className="form-control textboxstyle"
                            name="password"
                            id="password"
                            type="password"
                            value={password}
                            placeholder="Enter Password"
                            onChange={(e) => {
                              this.updateState("password", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <input
                            className="form-control textboxstyle"
                            name="confirmpassword"
                            id="confirmpassword"
                            type="password"
                            placeholder="Enter Confirm Password"
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="button button-contactForm boxed-btn"
                        onClick={() => {
                          this.signupSubmit();
                        }}
                      >
                        Create Your Account
                      </button>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        {/* <section className="about-area2 fix pb-padding pt-50 pb-80">
          <div className="container">
            
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <input
                    className="form-control textboxstyle"
                    name="name"
                    id="name"
                    type="text"
                    onfocus="this.placeholder = ''"
                    onblur="this.placeholder = 'Enter Your Name'"
                    placeholder="Enter Your Name"
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <input
                    className="form-control valid textboxstyle"
                    name="email"
                    id="email"
                    type="email"
                    onfocus="this.placeholder = ''"
                    onblur="this.placeholder = 'Enter email address'"
                    placeholder="Email"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <input
                    className="form-control textboxstyle"
                    name="password"
                    id="password"
                    type="text"
                    onfocus="this.placeholder = ''"
                    onblur="this.placeholder = 'Enter Password'"
                    placeholder="Enter Password"
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <input
                    className="form-control textboxstyle"
                    name="confirmpassword"
                    id="confirmpassword"
                    type="text"
                    onfocus="this.placeholder = ''"
                    onblur="this.placeholder = 'Enter Confirm Password'"
                    placeholder="Enter Confirm Password"
                  />
                </div>
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="button button-contactForm boxed-btn"
              >
                Create Your Account
              </button>
            </div>
            
          </div>
        </section> */}
        <Footer></Footer>
      </>
    );
  }
}
export default SignUp;
