import logo from "./logo.svg";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { Redirect } from "react-router";

import FrontendLayout from "./screen/layout/frontend/FrontendLayout";
import Home from "./screen/frontend/Home";
import About from "./screen/frontend/About";
import SignUp from "./screen/frontend/auth/SignUp";
// import { AuthProvider } from "react-auth-kit";
// import { RequireAuth } from "react-auth-kit";

function App() {
  return (
    // <AuthProvider
    //   authType={"cookie"}
    //   authName={"_auth"}
    //   cookieDomain={window.location.hostname}
    //   cookieSecure={window.location.protocol === "https:"}
    // >
    <div className="App">
      <Routes>
        {/* <Route exact path="/" element={<Home />} /> */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/about" element={<About />} />
      </Routes>
    </div>
    // </AuthProvider>
  );
}

export default App;
