import React, { Component } from "react";
import { useNavigate, Link } from "react-router-dom";

function Header() {
  const navigate = useNavigate();

  const logoutClick = () => {
    localStorage.clear();
    navigate("/login", { state: { path: "logout" } });
  };

  return (
    <header>
      {/* <!-- Header Start --> */}
      <div className="header-area header-transparent">
        <div className="main-header header-sticky">
          <div className="container-fluid">
            <div className="menu-wrapper d-flex align-items-center justify-content-between">
              {/* <!-- Logo --> */}
              <div class="logo">
                <Link to="index.html">
                  <img src="assets/img/logo/logo.png" alt="" />
                </Link>
              </div>
              {/* <!-- Main-menu --> */}
              <div className="main-menu f-right d-none d-lg-block">
                <nav>
                  <ul id="navigation">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/courses">Courses</Link>
                    </li>
                    <li>
                      <Link to="/pricing">Pricing</Link>
                    </li>
                    <li>
                      <Link to="gallery.html">Gallery</Link>
                    </li>
                    <li>
                      <Link to="blog.html">Blog</Link>
                      <ul className="submenu">
                        <li>
                          <Link to="blog.html">Blog</Link>
                        </li>
                        <li>
                          <Link to="blog_details.html">Blog Details</Link>
                        </li>
                        <li>
                          <Link to="elements.html">Elements</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="contact.html">Contact</Link>
                    </li>
                    <li>
                      <a href="https://gym-admin.discover-tech.co.in/">LogIn</a>
                    </li>
                    <li>
                      <Link to="/signup">Sign-Up</Link>
                    </li>
                  </ul>
                </nav>
              </div>
              {/* <!-- Header-btn --> */}
              <div className="header-btns d-none d-lg-block f-right">
                <Link to="contact.html" className="btn">
                  Contact me
                </Link>
              </div>
              {/* <!-- Mobile Menu --> */}
              <div className="col-12">
                <div className="mobile_menu d-block d-lg-none"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Header End --> */}
    </header>
  );
}
export default Header;
